import React from 'react';
import styles from './LandingPage.module.css';
import fileIcon from 'assets/images/fileIcon.svg';

interface UploadAreaProps {
  getInputProps?: () => any;
}

const UploadArea: React.FC<UploadAreaProps> = () => (
  <div className={styles.uploadAreaContainer}>
    <img className={styles.uploadIcon} loading="lazy" src={fileIcon} alt="file icon" />
    <div className={styles.uploadActionsContainer}>
      <span className={styles.actionText}>Upload Your Reference to find a Match</span>
    </div>
    <div className={styles.fileTypesInfo}> Video (mp4,mov), Audio (mp3), & Briefs (Doc, Pdf)</div>
  </div>
);

export default UploadArea;
